<template>
  <b-card bg-variant="light" no-body class="my-3">
    <b-card-img-lazy :src="item.images[Math.floor(item.images.length*Math.random())]" top v-bind="mainProps"></b-card-img-lazy>

    <b-card-body>
      <b-card-title>
        <router-link :to="item.link">{{item.name}}</router-link>
      </b-card-title>
      <b-card-text>
        {{item.subName}}
      </b-card-text>
    </b-card-body>

    <!-- <b-card-body v-else-if="page === 'rooms'">
      <b-card-title>{{item.name}}</b-card-title>
      <b-card-text>
        <div v-html="item.description"></div>
      </b-card-text>
    </b-card-body>

    <b-card-body v-else :title="item.description ? item.name : ''">
      <b-card-text>
        {{ item.description }}
      </b-card-text>
    </b-card-body> -->

</b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { BCard, BCardText, BCardImgLazy, BCardBody, BCardTitle } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardText,
    BCardImgLazy,
    BCardBody,
    BCardTitle,
    // Readmore: () => import('@/components/productPage/atoms/Readmore'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    page: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#ddd',
        width: 600,
        height: 400,
      },
    };
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>

<style scoped>
.no-body .card-body {
  padding: 0;
}
</style>
